<template>
<div style="height: 100%; position: relative; overflow:hidden;">
    <div id="map-container" style="height: 100%;">
    </div>
    <v-menu bottom left style="z-index:1050;">
        <template v-slot:activator="{ on }">
            <v-btn 
                small fixed dark fab bottom right color="orange" style="opacity: 0.80; top:80px; right:80px; z-index:1050;" 
                elevation-1 v-on="on" title="Changer le fond de carte">
                  <v-icon>mdi-map-legend</v-icon>
            </v-btn>
        </template>
            <v-list dense class="rounded-lg">
                <template v-for="(item, pindex) in tileLayer">
                <v-list-item :key="pindex" @click="changeTileLayer(pindex)">
                  <v-list-item-action>
                      <img :src="item.thumbnail" width="65" class="rounded-lg" :style="(activeTile == pindex) ? 'border: 3px solid orange;' : ''" />
                    </v-list-item-action>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item>
                </template>
            </v-list>
    </v-menu>
    <v-menu v-model="layerMenu" :close-on-content-click="false" bottom offset-y :max-height="550" style="z-index:1051;">
        <template v-slot:activator="{ on }">
            <v-btn small title="Couches SIG" color="primary" v-on="on" fixed dark fab bottom right 
                style="opacity: .80; top:80px; z-index:1040;">
                <v-icon>mdi-layers</v-icon>
                <v-badge v-show="activeLayers" :color="(activeLayers == 0) ? 'red' : 'green'"><span slot="badge">{{ activeLayers }}</span></v-badge>
            </v-btn>
        </template>
        <v-app-bar :color="(loading.updateSubarea) ? 'red' : 'primary'" dense dark>
            <v-toolbar-title>Parcelles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn title="Afficher tout" icon color="green" @click="toggleLayerView(true)"><v-icon>mdi-eye-check-outline</v-icon></v-btn>
            <v-btn title="Cacher tout" icon color="grey" @click="toggleLayerView(false)"><v-icon>mdi-eye-off-outline</v-icon></v-btn>
            <v-btn title="Calculer les aires" icon color="white" @click="updateSubareaArea()" 
                v-if="$root.$permissions.Generic.before" :disabled="loading.updateSubarea">
            <v-icon>mdi-update</v-icon>
            </v-btn>
            <v-btn title="Couleur par Etat / Type / Date" icon :color="colorTypColor[colorTyp]" @click="toggleLayerColorType()"><v-icon>mdi-palette</v-icon></v-btn>
            <v-btn title="Afficher les semaines" icon :color="(weekView) ? 'green' : 'white'" @click="displayWeekTask()"><v-icon>mdi-calendar-week-begin-outline</v-icon></v-btn>
        </v-app-bar>
        <v-list>
        <v-list-item v-for="(item, index) in listArea" :key="index" >
            <v-list-item-action @click="showHideLayer(index)" class="mr-1">
                <v-icon :color="(item.are_active) ? 'green' : 'gray'" style="cursor:pointer;">mdi-eye</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="selectLayerColor(index)" class="mr-1">
                <v-icon :color="item.are_color" style="cursor:pointer;">mdi-palette</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="editLayer(index)" class="mr-1">
                <v-icon :color="(item.edit)  ? 'green' : 'gray'" style="cursor:pointer;">{{(item.edit) ? 'mdi-content-save-all-outline' : 'mdi-pencil-outline'}}</v-icon>
            </v-list-item-action>
            <v-list-item-action @click="fitToLayer(index)" class="mr-1">
                <v-icon color="grey" style="cursor:pointer;">mdi-crosshairs-gps</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.are_name }}</v-list-item-title>
        </v-list-item>
        </v-list>
    </v-menu>
    <v-btn 
        fixed dark fab bottom right :color="(addAlertState) ? 'red' : 'grey'" style="opacity: 0.80; top:150px; z-index:1040;" 
        elevation-1 @click="activeAlert(!addAlertState)" title="Créer une alerte">
        <v-icon>mdi-alert</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="(modFence) ? 'green' : 'grey'" style="opacity: 0.80; top:220px; z-index:1040;" 
        :loading="loading.fenceLoading" elevation-1 @click="toggleModeFence()" title="Taches clôture">
        <v-icon>mdi-fence</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="(gpsView) ? 'green' : 'grey'" style="opacity: 0.80; top:320px; z-index:1040;" 
        elevation-1 @click="displayTruck()" title="Afficher gps">
        <v-icon>mdi-dump-truck</v-icon>
    </v-btn>
    <v-btn 
        fixed dark fab bottom right :color="'purple'" 
        style="opacity: 0.80; top:420px; z-index:1040;"
        :loading="loading.pdf"
        elevation-1 @click="downloadMap()" title="Télécharger la carte">
        <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>

    <!-- Selection de couleurs -->
    <v-dialog v-if="colorDialog" v-model="colorDialog" hide-overlay max-width="600px" style="z-index:1051;">
    <v-card>
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Choisir la couleur du calque :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="colorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-color-picker @input="saveLayerColor" :value="pickerColor" class="ma-2 elevation-0"
                hide-inputs hide-canvas mode="hexa" ></v-color-picker> 
        </v-card-text>
    </v-card>
    </v-dialog>
    <!-- Selection de layer creation -->
    <!-- <v-dialog v-if="activeSlider" v-model="layersDialog" hide-overlay scrollable content-class="selected-layer-dialog"> -->
    <v-card v-if="layersDialog" width="550px" style="bottom: 10px; right: 10px; z-index: 1050; position: absolute;">
        <v-app-bar dark color="primary" dense>
        <!-- <v-app-bar-nav-icon><v-icon >mdi-filter-outline</v-icon></v-app-bar-nav-icon>-->
        <v-toolbar-title>Selection: {{ subAreaSelected.length }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="removeSelectionLayer()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-app-bar>
        <v-card-text class="pa-0" style="max-height: 400px; overflow-y: scroll;">
            <v-list dense class="rounded-lg">
                <template v-for="(item, index) in selectedLayers">
                <v-list-item  :key="index">
                    <v-list-item-title>
                        <v-chip class="ma-0 mr-2" color="orange" label outlined @click="editSubarea(item.sar_id)">{{item.sar_code}} - {{item.sar_letter}}</v-chip>
                        {{item.are_name}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right">{{ item.sar_area.toLocaleString('fr-FR') + ' ' + getCacheType('UNI', item.sar_typ_id_unit).typ_name }}</v-list-item-subtitle>
                </v-list-item>
                </template>
            </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
            <v-list dense style="width:100%;">
                <template v-for="(item, index) in selectedLayersSum">
                <v-list-item  :key="index" style="min-height: 25px !important;">
                    <v-list-item-title>Total {{index}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">{{item.toLocaleString('fr-FR')}}</v-list-item-subtitle>
                </v-list-item>
                </template>
            </v-list>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions class="pa-1">
            <v-btn rounded color="primary" class="ml-3" :loading="saveProgress" @click.native="viewList()" :disabled="saveProgress">
                <v-icon left dark>mdi-eye-outline</v-icon>Consulter
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn rounded color="success" class="ml-3" :loading="saveProgress" @click.native="createList()" :disabled="saveProgress">
                <v-icon left dark>mdi-plus-box-multiple-outline</v-icon>Créer
            </v-btn>
        </v-card-actions>
    </v-card>
    <!-- ------------Alerte view ----------------- -->
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:1050;">
        <v-card min-width="350px" flat class="rounded-lg">
        <v-toolbar color="primary" dark flat height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="elevation-0 pa-0">
            <EditEvent
                v-if="modalEvent" :switchModal="switchModal"
                :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
                :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
                :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates" />
        </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import LfMap            from '@/plugins/map';
import { cacheGetters } from "@/store/cache.module"
import { areObj }       from '@/mixins/are_obj.js'
import { sarObj }       from '@/mixins/sar_obj.js'
import { taskUpdateService, filterUpdateService } from '@/rxjsServices';
import { bus } from '@/plugins/bus'
import EditEvent  from '@/components/events/EventForm.vue'
import LeafletImg from 'leaflet-image';

var mapthis = { map: {} }

export default {
    name: 'maptool',
    components: { EditEvent },
    props: ['toast', 'filterView'],
    mixins: [ areObj, sarObj ],
    data: function () {
        return {
            saveProgress: false,
            loading: { updateSubarea: false, fenceLoading: false, pdf: false },
            addressTab: [],
            search: null,
            searchWord: '',
            timeOutHandle: 'off',
            activeTile: 'ign',
            searchAddress: null,
            activeLayer: {},
            subAreaList: [], subAreaSelected: [], subAreaSelectedTotal: [], selectedTask: [],
            tileLayer: {
                ign : { text: 'Fond de plan IGN',   thumbnail: require('../assets/plan_ign.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                osm : { text: 'Fond de plan OSM',   thumbnail: require('../assets/plan_osm.jpg'),   link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/arcgistiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bph : { text: 'Fond de plan photo', thumbnail: require('../assets/plan_photo.jpg'), link: 'https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/igntiles/webmercator/{z}/{x}/{y}.png', color: 'green', icon: 'mdi-check'},
                bwt : { text: 'Sans fond',          thumbnail: require('../assets/plan_sans.jpg'),  link: '', color: 'green', icon: 'mdi-check'},
            },
            layerMenu: false, colorDialog: false, pickerColor : null,
            addAlertState: false, modalEvent: false, loadAlert: false, modFence: false,
            selectionEvent: { xxxId: 0, eveId:0, eveTable: "alt", eveType: "ALT", title:"Alerte", cooridnates:null },
            taskOptions : '',
            subToDelete: [],
            webSocketGps: null,
            gpsView: false,
            colorTyp: 'typ', // tty // dat
            colorTypColor: { 'typ': 'white', 'tty': 'green', 'dat': 'orange' },
            weekView: false,
            colorPalette : ['#2196F3', '#009688', '#CDDC39', '#FF9800', '#9C27B0', '#607D8B', '#F44336', '#673AB7', '#03A9F4', '#4CAF50', '#FFEB3B', '#795548' ]
        }
    },
    watch: { },
    computed: {
        activeSlider: function(){
            return (this.$route.meta.component == 'taskMassCreate') ? false : true
        },
        activeLayers: function(){
            var totalActive = 0
            for( var prop in this.listArea ){
                if( this.listArea[prop].active ){
                    totalActive += 1
                }
            }
            return totalActive
        },
        selectedLayers: function(){
            let items = []
            this.subAreaList.forEach( (item) => {
                if( this.subAreaSelected.indexOf(item.sar_id) > -1 ){
                    items.push(item)
                }
            })
            return items
        },
        selectedLayersSum: function(){
            let items = {}
            this.subAreaList.forEach( (item) => {
                if( this.subAreaSelected.indexOf(item.sar_id) > -1 ){
                    items[item.sar_letter]  = (items[item.sar_letter]) ? items[item.sar_letter] : 0
                    items[item.sar_letter] += item.sar_area
                }
            })
            return items 
        },
        layersDialog: function() {
            return (this.subAreaSelected.length > 0) ? true : false
        }
    },
    created(){
        // modification au niveau du filtre
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                //this.taskOptions = message.urlTask
                if(message == 'zoneswitch'){
                    this.initMap()
                } else {
                    this.reloadMapItems()
                }
            }
        })
        // Création / modification d'une tache
        this.subscribeTask = taskUpdateService.getTask().subscribe(message => {
            if (message) {
                this.displaySubarea()
            }
        })
    }, 
    beforeMount() { },
    mounted() {
        bus.$on('event:open', (obj) => this.viewAlert(obj))
        bus.$on('subarea:delete', (id) => { this.subToDelete.push(id); })
        bus.$on('massedit:close', () => { this.removeSelectionLayer(); })
        bus.$on('event:updateloc', (event) => { this.updateAlert(event); })
        mapthis.map = new LfMap('map-container')
        mapthis.map.getCacheType = this.getCacheType
        mapthis.map.selectedSar  = this.subAreaSelected
        //mapthis.map.selectedTask = this.selectedTask

        if( this.$route.params.latlng !== undefined && this.$route.params.latlng.length > 0 ){
            mapthis.map.mapCenter = this.$route.params.latlng.split(',')
        }

        this.initMap()        
    },
    methods:{
        async initMap(){
            await mapthis.map.removeAllLayer()
            if( !mapthis.map.Map ){
                await this.displayMap()
            }
            await this.getAreaIndex(false, false)
            this.displaySubarea()
            this.displayAlert()
        },
        displayMap() {
            return new Promise( (resolve) => {
                mapthis.map.setDrawSave = this.setDrawSave
                mapthis.map.display( [48.999537484204, 2.5624986257931], 13, 'EPSG3857' )
                resolve('resolved')
            })
        },
        async reloadMapItems(){
            await this.displayTask()
            await this.displayLinearTask()
            this.loadAlert = false
            this.displayAlert()
        },
        async displaySubarea(){
            await mapthis.map.removeAllSubAreaLayer()
            this.subAreaList = []
            for (const area of this.listArea) {
                this.$set(area, 'are_active', true)
                this.$set(area, 'edit', false)
                let option = 'per_page=false&sar_are_id=' + area.are_id + '&extended=withsubarea' //withcounttask &order_by=tsk_sta_id
                await this.getSubareaIndex(0, false, option).then( () => {
                    this.subAreaList.push( ...this.listSubarea )
                    mapthis.map.displaySubarea(this.listSubarea, area)
                })
            }
            // display linear tasks
            await this.displayLinearTask()
            await this.displayTask()
        },
        displayLinearTask(){
            return new Promise( (resolve) => {
                mapthis.map.removeLinearTasks()
                let url    = '/tasks/?per_page=false&extended=withtasklinear,withtasktype,withsubarea&min_tal_tsk_id=1'
                let option = cacheGetters.getFilterUrl()
                url    += ( option.length ) ? '&' + option : ''
                this.$http.get( url ).then( (response) => {
                    for (const linear of response.data?.data) {        
                        mapthis.map.displayLinearTask(linear)
                    }
                })
                resolve('resolved')
            })
        },
        displayTask(){
            return new Promise( (resolve) => {
                let url    = '/tasks/?per_page=false&extended=withcounttaskbyarea,withsubarea,withtasktype'
                let option = cacheGetters.getFilterUrl()
                let objFilter = cacheGetters.getFilter()
                objFilter.colorPalette = this.colorPalette
                if( !option.length ){
                    mapthis.map.displayTaskOnSubarea([])
                    resolve('resolved') // if no filter just clear list
                } else {
                    url    += ( option.length ) ? '&' + option : ''
                    this.$http.get( url ).then( (response) => {
                        if( Object.prototype.toString.call(response.data.data) === '[object Array]' ){
                            mapthis.map.displayTaskOnSubarea(response.data.data, this.colorTyp, objFilter)
                        }
                    })
                    resolve('resolved')
                }
            })
        },
        removeSelectionLayer(){
            mapthis.map.unSelectLayer()
        },
        createList(){
            let params = { tsk_id: 1, subAreaSelected: this.subAreaSelected, subAreaList: this.subAreaList, fence: false } 
            // Si on est en mode clôture
            if( this.modFence ){
                params.taskLine = mapthis.map.getwallMarker()
                params.fence    = true
            }
            this.$router.push({
                name: "map_slide_creat", //use name for router push
                params: params
            })
        },
        editSubarea(sarId){
            this.$router.push({
                name: "map_slide_edit_sar", //use name for router push
                params: { tsk_id: sarId, subAreaSelected: this.subAreaSelected, subAreaList: this.subAreaList }
            })
        },
        viewList(){
            this.$router.push({
                name: "map_slide_edit", //use name for router push
                params: { tsk_id: 1, subAreaSelected: this.subAreaSelected, subAreaList: this.subAreaList, selectedTask: mapthis.map.selectedTask }
            })
        },
        locateMe(){
            this.locatemeState = !this.locatemeState
            mapthis.map.locateMe(this.addMarkerState, this.locatemeState)
        },
        changeTileLayer(id){
            this.activeTile = id
            mapthis.map.setTileLayer(this.tileLayer[id].link)
        },
        selectLayerColor(id){
            this.currentLayer     = id
            this.pickerColor      = this.hexToRgbA( this.listArea[ id ].are_color )
            this.layerMenu        = false
            this.colorDialog      = true
        },
        saveLayerColor(color){
            var hexColor = "#" + ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b).toString(16).slice(1)
            this.listArea[ this.currentLayer ].are_color = hexColor
            
            //mapthis.map.setLayerColor( 'areaList', this.currentLayer, hexColor )
            //mapthis.map.setLayerTransparency( 'areaList', this.currentLayer, color.a )
        },
        showHideLayer(id){
            this.listArea[id].are_active = !this.listArea[id].are_active
            mapthis.map.showHideLayer('areaList', this.listArea[id].are_id)
        },
        toggleLayerView(state){
            return new Promise( (resolve) => {
                for( const id in this.listArea){
                    if( this.listArea[id].are_active !== state ){
                        this.listArea[id].are_active = !this.listArea[id].are_active
                        mapthis.map.showHideLayer('areaList', this.listArea[id].are_id)
                    }
                }
                resolve('resolved')
            })
        },
        fitToLayer(id){
            mapthis.map.fitToLayer('areaList', this.listArea[id].are_id)
        },
        async editLayer(id){
            if( this.listArea[id].edit ){
                let geojson = mapthis.map.getlayerDraw('areaList', this.listArea[id].are_id)
                for( const key in geojson.features ){
                    let subLayer = geojson.features[key]
                    if( subLayer?.properties?.sar_id ){
                        // Layer edited
                        await this.$http.put( '/subareas/' + subLayer.properties.sar_id, {sar_geojson : subLayer.geometry.coordinates} )
                    } else {
                        let sar = { 'sar_are_id': this.listArea[id].are_id, 'sar_geojson': JSON.stringify(subLayer.geometry.coordinates) }
                        await this.$http.post( '/subareas/', sar)
                    }
                }
                for( const key in this.subToDelete ){
                    await this.$http.delete( '/subareas/' + this.subToDelete[key] )
                }
                mapthis.map.disableEditLayer('areaList', this.listArea[id].are_id)
                this.listArea[id].edit = false
            } else {
                // Ré initialisation des deletes
                this.subToDelete = []
                for( const key in this.listArea ){
                    this.listArea[key].edit = false
                    if( ( parseInt(key) !== parseInt(id) ) && this.listArea[key].are_active ){
                        this.listArea[key].are_active = !this.listArea[key].are_active
                        mapthis.map.showHideLayer('areaList', this.listArea[key].are_id)
                    } else if( ( parseInt(key) == parseInt(id) ) && !this.listArea[key].are_active ){
                        this.listArea[key].are_active = !this.listArea[key].are_active
                        mapthis.map.showHideLayer('areaList', this.listArea[key].are_id)
                    }
                }
                this.listArea[id].edit = true
                mapthis.map.editLayer('areaList', this.listArea[id].are_id)
            }
        },
        activeAlert(state){
            this.addAlertState = state
            mapthis.map.activeAlert(state)
        },
        switchModal(name, mode){
            name    = name || 'dialogObj'
            mode    = mode || false
            this[name] = mode
            if( name == 'modalEvent' && !mode ){
                //mapthis.map.removeAllAlert()
                this.loadAlert = false
                this.displayAlert()
                mapthis.map.markerDrag = null
            }
        },
        displayAlert(){
            return new Promise( (resolve, reject) => {
                mapthis.map.removeAllAlert()
                this.loadAlert  = true
                this.alertArea  = []

                let url    = '/events/?eve_table_id=zon,tsk&per_page=false' //eve_typ_id=%MEV%&
                let option = cacheGetters.getFilterUrl('eve')
                if( this.loadAlert && option.length ){ // no result if no filter
                    url    += ( option.length ) ? '&' + option : ''
                    this.$http.get( url ).then( (response) => {
                        var kp, vp
                        for (kp = 0; (vp = response?.data?.data[kp]) !== undefined; kp++) {
                            if( vp.eve_lat && vp.eve_lng && ( vp.eve_table_id == 'zon' || vp.eve_table_id == 'tsk' ) ){
                                vp.eve_color = this.getCacheState('TSK', vp.eve_sta_id).sta_color
                                mapthis.map.addAlert( vp )
                            } else if( vp.eve_table_id == 'are' ) {
                                this.alertArea.push( vp.eve_xxx_id )
                            }
                        }
                        mapthis.map.udpateLayerStyle( this.alertArea )
                        this.loadAlert  = false
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                } else {
                    mapthis.map.removeAllAlert()
                }
            })
        },
        viewAlert( altObj ){
            // Si transformé en tache
            if( altObj.eve_table_id == 'tsk' ){
                this.$router.push({
                    name: "map_slide_edit_task", //use name for router push
                    params: { tsk_id: altObj.eve_xxx_id }
                })   
            } else {
                this.selectionEvent.xxxId    = 0
                this.selectionEvent.eveId    = altObj.eve_id
                this.selectionEvent.eveTable = 'zon'
                this.selectionEvent.eveType  = "MEV"
                this.selectionEvent.title    = 'Evenement'
                this.selectionEvent.cooridnates    = { lat: altObj.eve_lat, lng: altObj.eve_lng }
                //this.alertDialog = true
                this.switchModal('modalEvent', 1)
            }
        },
        async updateAlert(event){
            await this.$http.put( '/events/' + event.eve_id, { eve_lat: event.eve_lat, eve_lng: event.eve_lng } )
            this.$root.$toast({ color: 'success', text: 'Emplacement mis à jour' })
        },
        hexToRgbA(hex){
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                a: 1
            } : null;
        },
        async updateSubareaArea(){
            this.loading.updateSubarea = true
            for(const subArea in this.subAreaList){
                var polygon = false
                try {
                    polygon = (this.subAreaList[subArea].sar_typ_id == 'SAR03') ? await mapthis.map.turfHelpers.polygon( JSON.parse(this.subAreaList[subArea].sar_geojson) ) : polygon
                    //polygon = (this.subAreaList[subArea].sar_typ_id == 'SAR02') ? await mapthis.map.turfHelpers.lineString( JSON.parse(this.subAreaList[subArea].sar_geojson) ) : polygon
                } catch (error) {
                    polygon = false
                }

                if( polygon && !this.subAreaList[subArea].sar_area_auto ){
                    let area = mapthis.map.turfArea(polygon)
                    let sarObj = { sar_id: this.subAreaList[subArea].sar_id, sar_area_auto: area }
                    if( !this.subAreaList[subArea].sar_area ){
                        sarObj.sar_area = area
                    }
                    await this.putSubareaUpdate( sarObj )
                }
            }
            this.loading.updateSubarea = false
        },
        async toggleModeFence(){
            this.loading.fenceLoading = true
            this.modFence = !this.modFence
            await mapthis.map.toggleModeFence(this.modFence)
            if( !this.modFence ){
                await this.toggleLayerView(false)
                await this.toggleLayerView(true)
            }
            this.displayLinearTask()
            this.loading.fenceLoading = false
        },
        displayTruck(){
            this.gpsView = !this.gpsView
            if( this.gpsView && (!this.webSocketGps || this.webSocketGps.readyState == undefined || this.webSocketGps.readyState !== 1) ){
                this.webSocketGps = new WebSocket( process.env.VUE_APP_GPS_URL );
                this.webSocketGps.onmessage = (msg) => {
                    var data = JSON.parse(msg.data);
                    mapthis.map.displayGps(data)

                }

                this.webSocketGps.onclose   = () => {
                    this.$root.$toast({ color: 'success', text: 'Déconnexion avec le serveur GPS' })
                }
            }
            if( this.gpsView == 'close' && this.webSocketGps.readyState != undefined && this.webSocketGps.readyState == 1 ){
                this.webSocketGps.close();
            }
        },
        displayWeekTask(){
            this.weekView = !this.weekView
            mapthis.map.displayWeekTask(this.weekView)
        },
        toggleLayerColorType(){
            let objFilter = cacheGetters.getFilter()
            if( objFilter.min_tsk_date_plan_arr && objFilter.min_tsk_date_plan_arr.length > 0 && objFilter.min_tsk_date_plan_arr.length == objFilter.max_tsk_date_plan_arr.length ){
                if( this.colorTyp == 'tty' ){ this.colorTyp = 'dat' }
                else if( this.colorTyp == 'dat' ){ this.colorTyp = 'typ'} 
                else if( this.colorTyp == 'typ' ){ this.colorTyp = 'tty' }
            } else {
                this.colorTyp = ( this.colorTyp == 'typ' ) ? 'tty' : 'typ'
            }
            let message = { 'typ': 'Par Etat', 'tty': 'Par type de tache', 'dat': 'Par date' }
            this.$root.$toast({ color: 'success', text: 'Mode de couleur: ' + message[this.colorTyp] })

            this.reloadMapItems()
        },
        async downloadMap(){
            await mapthis.map.removeAllAlert()
            return new Promise( (resolve) => {
                
                this.loading.pdf = true
                LeafletImg(mapthis.map.Map, (err, canvas) => {
                    var img  = canvas.toDataURL();
                    let data = cacheGetters.getFilterUrlForm()
                    data.per_page = 'false'
                    data.extended = 'withtasktype,withsubarea,withtasktypeinter,withstate,witharea'
                    data.tmpimage = img

                    var form = { 
                        method: 'post', url: '/report/mapextraction/', responseType: 'blob', 
                        data: data
                    }
                    this.displayAlert()
                    this.$http.customRequest(form).then( (response) => {
                        this.loading.pdf = false
                        this.$download(response.data, 'report.pdf', response.headers['content-type'])
                    })
                    resolve('resolved')
                })
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
    },
    beforeDestroy() {
        this.subscribeTask.unsubscribe()
        this.subscribeFilter.unsubscribe()
        if( this.webSocketGps && this.webSocketGps.readyState != undefined && this.webSocketGps.readyState == 1 ){
                this.webSocketGps.close();
            }
    }
}
</script>

<style lang="scss" scoped>
@import "../../node_modules/leaflet/dist/leaflet.css";
@import '../assets/leaflet.pm.css';
@import '../assets/leaflet.css';
@import '../assets/map.css';
</style>