/* *****************************
    Objet : Area
******************************** */
import { cacheGetters, cacheMutations, cacheActions } from "@/store/cache.module"

export const areObj = {
    data: function () {
        return {
            objArea: {
                are_id: 0, 
                are_uid: null, 
                are_zon_id: 0, 
                are_sta_id: 'GEN01', 
                are_typ_id: 'ARE01', 
                are_name: '', 
                are_color: '#3388ff', 
                are_field: [], 
                are_created_at: null, 
                are_updated_at: null
            },
            objAreaClone: {},
            listArea: [],
            metaArea: {},
        }
    },
    mounted: function (){
        this.objAreaClone = JSON.stringify( this.objArea )
    },
    methods: {
        getAreaFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        // Get Area
        getAreaShow(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/areas/' + this.objArea.are_id).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objArea[prop] !== undefined ){
                            this.objArea[prop] = response.data.data[prop] 
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getAreaIndex(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order = order || ''
                this.listArea = []
                //let searchCriteria = '' //this.getAreaFilter(page, perPage)
                let storedCache = cacheGetters.getCacheInfo()
                let user        = cacheGetters.getUser()
                let areas       = cacheGetters.getArea(user.zoneId)
                if( user['cacheversions'] && storedCache['v_area'] == user?.cacheversions?.v_area && areas.length > 0 ){
                    this.listArea = areas
                    resolve('resolved')
                } else {
                    this.$http.get( '/areas/?per_page=false&' + order ).then( (response) => {
                        this.metaArea = response.data.meta
                        if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                            this.listArea = response.data.data
                            storedCache['v_area'] = response.data.meta.version
                            cacheMutations.setCacheInfo( storedCache )
                            cacheMutations.setArea( response.data.data )
                            cacheActions.storeCacheToStorage()
                        }
                        resolve('resolved')
                    }).catch( (error) => { 
                        reject(error)
                    })
                }
            })
        },
        putAreaUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/areas/' + this.objArea.are_id, this.objArea ).then( (response) => {
                    this.objArea.are_id = response.data.data.are_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postAreaStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/areas', this.objArea ).then( (response) => {
                    this.objArea.are_id = response.data.data.are_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteObjArea(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/areas/' + this.objArea.are_id, this.objArea ).then( () => {
                    this.objArea.are_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanObjArea(){
            var AreaClone = JSON.parse( this.objAreaClone )
            var prop
            for( prop in AreaClone ){
                if( this.objArea[prop] !== undefined ){
                    this.objArea[prop] = AreaClone[prop]
                }
            }
        }
    }
}