/* *****************************
    Objet : Subarea
******************************** */
import { cacheGetters } from "@/store/cache.module"

export const sarObj = {
    data: function () {
        return {
            objSubarea: {
                sar_id: 0, 
                sar_uid: '', 
                sar_are_id: 0, 
                sar_sta_id: 'GEN01', 
                sar_typ_id: 'SAR01', 
                sar_code: '', 
                sar_letter: '', 
                sar_typ_id_unit: 'UNI01', 
                sar_area: 0, 
                sar_geojson: '', 
                sar_usr_id_updated: 0, 
                sar_created_at: null, 
                sar_updated_at: null
            },
            objSubareaClone: {},
            listSubarea: [],
            metaSubarea: {},
        }
    },
    mounted: function (){
        this.objSubareaClone = JSON.stringify( this.objSubarea )
    },
    methods: {
        getSubareaFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        // Get Subarea
        getSubareaShow(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/subareas/' + this.objSubarea.sar_id).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objSubarea[prop] !== undefined ){
                            this.objSubarea[prop] = response.data.data[prop] 
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getSubareaIndex(page, perPage, options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                this.listSubarea = []
                let searchCriteria = '' //this.getSubareaFilter(page, perPage)
                searchCriteria = (searchCriteria.length == 0) ? '?' : searchCriteria;

                this.$http.get( '/subareas/' + searchCriteria + options ).then( (response) => {
                    this.metaSubarea = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listSubarea = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putSubareaUpdate(obj){
            return new Promise( (resolve, reject) => {
                obj = (obj) ? obj : this.objSubarea // partial update
                this.$http.put( '/subareas/' + obj.sar_id, obj ).then( (response) => {
                    this.objSubarea.sar_id = (response.data.data) ? response.data.data.sar_id : response.data.sar_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postSubareaStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/subareas', this.objSubarea ).then( (response) => {
                    this.objSubarea.sar_id = (response.data.data) ? response.data.data.sar_id : response.data.sar_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteObjSubarea(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/subareas/' + this.objSubarea.sar_id, this.objSubarea ).then( () => {
                    this.objSubarea.sar_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanObjSubarea(){
            var SubareaClone = JSON.parse( this.objSubareaClone )
            var prop
            for( prop in SubareaClone ){
                if( this.objSubarea[prop] !== undefined ){
                    this.objSubarea[prop] = SubareaClone[prop]
                }
            }
        }
    }
}