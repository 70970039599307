import L    from 'leaflet';
// eslint-disable-next-line no-unused-vars
import MarkerCluster from 'leaflet.markercluster'
// eslint-disable-next-line no-unused-vars
import LeafleatPm    from 'leaflet.pm';
import turfArea      from '@turf/area'
import turfDistance  from '@turf/distance'
import turflineChunk from '@turf/line-chunk'
import turflineSlice from '@turf/line-slice'
//import turfsimplify from '@turf/simplify'
import * as turfHelpers   from '@turf/helpers'

import { cacheGetters } from "@/store/cache.module"
import { bus } from '@/plugins/bus'

class LfMap {
    constructor(mapContainer) {
      this.layerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
      this.mapContainer = mapContainer;
      this.baseLayerMap = this.setLayer('base');
      this.Map          = null;
      this.leaflet       = window.L;
      this.defautLatLng  = [48.999537484204, 2.5624986257931];
      this.markerLoc     = [0, 0];
      this.markerDrag    = null;
      this.circleMarker  = null;
      this.svgIcon       = {
        truck: '<div class="marker-svg marker-truck"><center></center></div>',
        gen: '<div class="marker-svg marker-gen"><center></center></div>'
      }
      /************* LAYERS *************/
      this.baseLayer     = {}
      this.layerDraw     = L.layerGroup()
      this.layerTree     = L.layerGroup()
      this.layerAlert    = L.layerGroup()
      this.layerWeek     = L.layerGroup()
      this.areaList      = [];
      this.linearList    = [];
      this.workLines     = L.layerGroup()
      this.truckGps      = L.layerGroup()
      /************* GEOJSON *************/
      this.geoJson       = {}
      /************* ******* *************/
      this.vehicleTo     = undefined;
      this.showObjectCard= function(){ alert('Action non paramétré') };
      this.getCacheType  = function(){ alert('Action non paramétré') };
      this.selectedSar   = [];
      this.editMode      = false;
      this.modeAlert     = false;
      this.modFence      = false;
      this.turfArea      = turfArea;
      this.turfHelpers   = turfHelpers;
      this.wallMarker    = []
      this.wallPoint     = { start : { lat:0, lng:0 }, end : { lat:0, lng:0 }, mileage: 0 }; // Linéaires pour Wall
      this.wallSimpleList= []
      this.selectedTask  = []
    }

    display(latlng, zoom, projection) {
      latlng     = latlng || this.defautLatLng;
      zoom       = zoom   || 18;
      this.zoom  = zoom;
      projection = projection || 'EPSG3857';
      this.projection = projection;

      var mapOptions = {
          zoom: zoom,
          zoomControl:false,
          center: latlng,
          crs: L.CRS[ projection ],
          preferCanvas: true,
          tapTolerance: 20,
          maxZoom : 28, minZoom: -20, maxNativeZoom: 16,
          layers: [this.baseLayerMap],
      };

      if( latlng !== undefined ){
          mapOptions.center = latlng
      }
      if( zoom !== undefined ){
          mapOptions.zoom = zoom
      }
      // Init MAP
      this.Map = new this.leaflet.map(this.mapContainer, mapOptions);
      
      this.layerWeek.addTo(this.Map); // week add
      this.layerDraw.addTo(this.Map);
      this.workLines.addTo(this.Map);
      this.truckGps.addTo(this.Map);

      /************ Action Dessin **********************/
      this.Map.on('pm:create', (e) => {
        if( !this.editMode ){
          setTimeout( () => {
            this.Map.removeLayer(e.layer);
            //this.showSelectionCard( e.layer.getBounds() );
          }, 600)
        }
      });
      this.Map.on('pm:remove', (e) => {
        if( e.layer?.feature?.properties?.sar_id ){
          bus.$emit('subarea:delete', e.layer.feature.properties.sar_id)
        }
      });
      /************ Action sur click carte **********************/
      this.layerAlert.addTo(this.Map);

      this.Map.on('click', (e) => {
        if (this.modeAlert) {
          this.addLocMarker({ lat: e.latlng.lat, lng: e.latlng.lng });
        }
        if( this.modFence ){
          this.clickOnFenceMode(e)
        }
      });

      //if( Object.prototype.toString.call( this.mapCenter ) === '[object Array]' ){
      //  this.Map.setView(new L.LatLng(this.mapCenter[0], this.mapCenter[1]), 20);
      //}
      this.addPmControl();
      return this.Map;
    }

    // Add Draw control
    addPmControl(options){
      // Leaflet PM
      options = options || {
        position     : 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
        drawPolygon  : true, // adds button to draw a polygon
        drawRectangle: true, // adds button to draw a rectangle
        drawPolyline : false, // adds button to draw a polyline
        drawMarker   : false, // adds button to draw markers
        drawCircle   : false, // adds button to draw a cricle
        drawCircleMarker: false,
        cutPolygon   : false, // adds button to cut a hole in a polygon
        editMode     : false, // adds button to toggle edit mode for all layers
        removalMode  : false, // adds a button to remove layers
        dragMode     : false
      };
      this.Map.pm.addControls(options);
    }

    enablePmDraw(tool) {
      this.Map.pm.enableDraw(tool, { snappable: false });
    }  

    displaySubarea(sarList, area){
      var self = this
      function layerOnEachFeature(feature, layer) {
        feature.properties.color = feature.properties.sar_color
        let fillOpacity = 0.4
        fillOpacity = (layer.feature.properties.fillOpacity) ? layer.feature.properties.fillOpacity : 0.4

        layer.setStyle( { weight:2, color: feature.properties.color, fillColor : feature.properties.sar_color, opacity:1, fillOpacity: fillOpacity } )

        layer.on('click', () => {
          if( !self.modFence && !self.modeAlert && layer.feature.properties.sar_selected && !self.editMode ){
            const layerIndex = self.selectedSar.indexOf(layer.feature.properties.sar_id)
            if (layerIndex > -1) { // only splice array when item is found
              layer.feature.properties.sar_selected = false
              self.selectedSar.splice(layerIndex, 1); // 2nd parameter means remove one item only
              layer.setStyle( { color: feature.properties.color, fillColor : feature.properties.sar_color, opacity:1, fillOpacity: layer.feature.properties.fillOpacity } )
            }
          } else if (!self.modFence && !self.modeAlert && !self.editMode) {
            layer.feature.properties.sar_selected = true
            self.selectedSar.push( layer.feature.properties.sar_id )
            layer.setStyle( { color: '#E65100', fillColor : '#FFD600', opacity:1, fillOpacity:1 } )
          }

        })
      }
      /* ********************************************************* */
      return new Promise( (resolve) => {
        let geoJsonTab = []

        for (const item of sarList) {
          let coordinates = {}
          try {
            coordinates = JSON.parse(item.sar_geojson)
          } catch (error) {
            console.log(error) //item.sar_geojson,
          }

          let geoJson = {
            type: "Feature",
            properties: JSON.parse( JSON.stringify(item) ),
            geometry: {
              type: this.getCacheType('SAR', item.sar_typ_id).typ_name,
              coordinates: coordinates
            }
          }
          geoJson.properties['tsk_week']     = 0
          geoJson.properties['sar_color']    = area.are_color
          geoJson.properties['sar_selected'] = false
          geoJsonTab.push(geoJson)
        }
        this.areaList[area.are_id] = L.geoJSON(geoJsonTab, {
          onEachFeature: layerOnEachFeature,
          //style: function() {return { color: 'red', fillColor : 'red', opacity:1, fillOpacity:1.2 };}
        }).addTo(this.Map)

        resolve('resolved')
      })
    }

    displayTaskOnSubarea(task, colorType, filter){
      colorType = (colorType) ? colorType : 'typ'
      filter    = filter || {}

      // reset color
      for(const areId in this.areaList){
        this.areaList[areId].eachLayer( (layer) => {
          layer.feature.properties.color = '#000'
          layer.feature.properties.sar_color = '#000'
          layer.feature.properties.fillOpacity = 0.4
          let fillOpacity = 0.4
          layer.setStyle( {
            weight:2, color: layer.feature.properties.color, fillColor : layer.feature.properties.sar_color, opacity:1, fillOpacity: fillOpacity
          })
        })
      }

      for(const tskId in task){
        if( this.areaList[task[tskId].sar_are_id] ){
          this.areaList[task[tskId].sar_are_id].eachLayer( (layer) => {
            if( parseInt(layer.feature.properties.sar_id) == task[tskId].tsk_sar_id ){
              layer.feature.properties.tsk_week = task[tskId].tsk_week // week add
              layer.feature.properties.color = layer.feature.properties.sar_color
              let fillOpacity = 1
              // manage color
              if( task[tskId].tsk_sta_id_concat.indexOf('TSK05') !== -1 ){
                layer.feature.properties.sar_color = cacheGetters.getStates('TSK', 'TSK05').sta_color
              } else if ( task[tskId].tsk_sta_id_concat.indexOf('TSK04') !== -1 ){
                layer.feature.properties.sar_color = cacheGetters.getStates('TSK', 'TSK04').sta_color
              } else {
                fillOpacity = 1
              }
              // mode color by type task
              if( colorType == 'tty' ){
                layer.feature.properties.sar_color = task[tskId].tty_color
              }
              // mode color by Date 
              if( colorType == 'dat' && filter.max_tsk_date_plan_arr.length ){
                let datePlan = parseInt(task[tskId].tsk_date_plan.replaceAll('-', ''))
                let i = 0, len = filter.max_tsk_date_plan_arr.length
                while (i < len) {
                  let dateStart = parseInt(filter.min_tsk_date_plan_arr[i].replaceAll('-', ''))
                  let dateEnd   = parseInt(filter.max_tsk_date_plan_arr[i].replaceAll('-', ''))
                  if( datePlan >= dateStart && datePlan <= dateEnd ){
                    layer.feature.properties.sar_color = filter.colorPalette[i]
                    break;
                  } else {
                    layer.feature.properties.sar_color = '#000000'
                  }
                  i++
                }
              }

              layer.feature.properties.fillOpacity = fillOpacity
              layer.feature.properties.color = layer.feature.properties.sar_color
              layer.setStyle({
                weight:2, color: layer.feature.properties.color, fillColor : layer.feature.properties.sar_color, opacity:1, fillOpacity: fillOpacity
              })
            }
          })
        }
      }
    }
    // Display linear Task on Linestring object
    displayLinearTask(item){
      return new Promise( (resolve) => {
        let flexi = 5
        item.tal_lat_start = Number.parseFloat(item.tal_lat_start).toFixed(flexi)
        item.tal_lng_start = Number.parseFloat(item.tal_lng_start).toFixed(flexi)
        item.tal_lat_end   = Number.parseFloat(item.tal_lat_end).toFixed(flexi)
        item.tal_lng_end   = Number.parseFloat(item.tal_lng_end).toFixed(flexi)

        let lines = []
        let leafletLayer
        let start
        let stop
        if( this.areaList[item.sar_are_id] ){
          this.areaList[item.sar_are_id].eachLayer( (layer) => {
            if( layer?.feature?.properties?.sar_id == item.tsk_sar_id ){
              let line  = this.turfHelpers.lineString( layer.feature.geometry.coordinates )
              start = this.turfHelpers.point([item.tal_lng_start, item.tal_lat_start]);
              stop  = this.turfHelpers.point([item.tal_lng_end, item.tal_lat_end]);
              let sliced = turflineSlice(start, stop, line);
              lines = sliced.geometry.coordinates
              leafletLayer = layer
            }
          })
        }
        let wallGeoJson = []
        wallGeoJson.push({
          "type": "Feature", 
          "properties": {
            "color": cacheGetters.getStates('TSK', item.tsk_sta_id).sta_color,
            "sar_color": cacheGetters.getStates('TSK', item.tsk_sta_id).sta_color,
            "sar_selected": false,
            "sar_id": item.tsk_sar_id,
            "tsk_id": item.tsk_id
          }, 
          "geometry": { "type": "LineString", "coordinates": lines },
          "layer" : '',
          "subLayer" : ''
        })
        var workLines = { 
          "type": "FeatureCollection", 
          "crs": { "type": "name", "properties": {
            "color": cacheGetters.getStates('TSK', item.tsk_sta_id).sta_color,
            "sar_color": cacheGetters.getStates('TSK', item.tsk_sta_id).sta_color,
            "sar_selected": false,
            "sar_id": item.tsk_sar_id
          }}, "features": wallGeoJson 
        }
        let self  = this
        let color = cacheGetters.getStates('TSK', item.tsk_sta_id).sta_color
        L.geoJSON( workLines, {
          onEachFeature: function onEachFeature(feature, layer) {
            layer.on('click', () => {
              if( !self.modFence && !self.modeAlert && leafletLayer.feature.properties.sar_selected ){
                const layerIndex = self.selectedSar.indexOf(leafletLayer.feature.properties.sar_id)
                if (layerIndex > -1) { // only splice array when item is found
                  leafletLayer.feature.properties.sar_selected = false
                  self.selectedSar.splice(layerIndex, 1); // 2nd parameter means remove one item only
                  leafletLayer.setStyle( { color: '#000', fillColor : '#000', opacity:1, fillOpacity: 1 } )
                  layer.setStyle( { color: layer.feature.properties.color, fillColor : layer.feature.properties.sar_color, opacity:1, fillOpacity:1, weight:5 } )
                  self.selectedTask = []
                }
              } else if (!self.modFence && !self.modeAlert) {
                leafletLayer.feature.properties.sar_selected = true
                self.selectedSar.push( leafletLayer.feature.properties.sar_id )
                leafletLayer.setStyle( { color: 'red', fillColor : 'red', opacity:1, fillOpacity:1 } )
                layer.setStyle( { color: 'blue', fillColor : 'blue', opacity:1, fillOpacity:1, weight:8 } )
                self.selectedTask.push(layer.feature.properties.tsk_id)
              }
            })
          },
          style: function() {
            return { 
              weight:5, 
              fillColor: color, 
              fillOpacity: 0.9, 
              color: color
            }
          }
        }).addTo(this.workLines)
        // Add circle visual marker
        if( start ){
          L.circle( [start.geometry.coordinates[1], start.geometry.coordinates[0]], { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: 0.5 } ).addTo(this.workLines)
          L.circle( [stop.geometry.coordinates[1], stop.geometry.coordinates[0]], { color: 'red', fillColor: '#f03', fillOpacity: 0.5, radius: 0.5 } ).addTo(this.workLines)
        }
        resolve('resolved')
      })
    }

    showHideLayer( layer, id ){
      if( this[layer][id] !== undefined && this.Map.hasLayer( this[layer][id] )) {
        this.Map.removeLayer( this[layer][id] )
      } else if( this[layer][id] !== undefined ){
        this.Map.addLayer( this[layer][id] )
      }
    }

    fitToLayer( layer, id ){
      if( this.Map.hasLayer( this[layer][id] )) {
        var bounds   = this[layer][id].getBounds()
        this.Map.fitBounds(bounds)
      }
    }
    // Changer la couleur d'un calque et de ses enfant
    setLayerColor(layer, id, color){
      if( this[layer][id] !== undefined ){
        if( typeof this[layer][id].setStyle === "function" ){
          this[layer][id].setStyle( { color: color, fillColor :color } )
        }
      }
    }
    // Changer la transparence
    setLayerTransparency(layer, id, percent){
      let opacity     = percent
      let fillOpacity = ( percent > 0 ) ? percent / 5 : 0
      if( this[layer][id] !== undefined ){
        if( typeof this[layer][id].setStyle === "function" ){
          this[layer][id].setStyle( { opacity: opacity, fillOpacity : fillOpacity } );
        }
      }
    }

    unSelectLayer(){
      this.areaList.forEach( (layers) => {
        layers.eachLayer( (layer) => {
          layer.feature.properties.sar_selected = false
          layer.setStyle( { color: layer.feature.properties.color, fillColor : layer.feature.properties.sar_color, opacity:1, fillOpacity:layer.feature.properties.fillOpacity } )
        })
      })

      this.workLines.eachLayer( (layers) => {
        if( typeof layers.eachLayer === "function" ){
          layers.eachLayer( (layer) => {
            layer.setStyle( { color: layer.feature.properties.color, fillColor : layer.feature.properties.sar_color, opacity:1, fillOpacity:1, weight:5 } )
          })
        }
      })
      this.selectedTask = []

      this.selectedSar.splice(0);
    }
    /********************Edit mode  ************************************/
    editLayer( layer, id ){
      //this.removeControls()
      //this.Map.pm.globalDragModeEnabled()
      if( this.Map.hasLayer( this[layer][id] )) {
        //this.Map.pm.globalDragModeEnabled()
        this[layer][id].pm.enable()
        this.editMode = true
      }
      this.addPmControl({
          position     : 'topleft', // toolbar position, options are 'topleft', 'topright', 'bottomleft', 'bottomright'
          drawPolygon  : true, // adds button to draw a polygon
          drawRectangle: true, // adds button to draw a rectangle
          drawPolyline : true, // adds button to draw a polyline
          drawMarker   : false, // adds button to draw markers
          drawCircle   : false, // adds button to draw a cricle
          drawCircleMarker: false,
          cutPolygon   : false, // adds button to cut a hole in a polygon
          editMode     : true, // adds button to toggle edit mode for all layers
          removalMode  : true, // adds a button to remove layers
          dragMode     : true
      })
      this.Map.on('pm:create', (e) => {
        this[layer][id].addLayer( e.layer )
      })
    }

    disableEditLayer( layer, id ){
      this.editMode = false
      if( this.Map.hasLayer( this[layer][id] )) {
        this[layer][id].pm.disable()
      }
    }

    getlayerDraw( layer, id ){
      return this[layer][id].toGeoJSON(15)
    }

    toggleModeFence(mode){
      return new Promise( (resolve) => {
        this.modFence = mode
        if( this.modFence ){
          this.wallSimpleList = []
          for( const areId in this.areaList ){
            this.areaList[areId].eachLayer( (layer) => {
              if(layer.feature.geometry.type == 'LineString'){
                let line = turflineChunk(layer.feature.geometry, 0.002)
                line.features.forEach(feature => {
                  feature.geometry.coordinates.forEach(latlng => {
                    this.wallSimpleList.push( {
                      latlng : [latlng[1], latlng[0]], 
                      sar_id : layer?.feature?.properties?.sar_id,
                      are_id : areId,
                      layer  : layer
                    })
                  })
                })
              } else {
                if( this.Map.hasLayer( layer )) {
                  this.Map.removeLayer( layer )
                }
              }
            })
          }
        } else {
          if( this.wallMarker[0] && this.Map.hasLayer( this.wallMarker[0] ) ){
            this.Map.removeLayer( this.wallMarker[0] )
            this.wallMarker[0] = null
          }
          if( this.wallMarker[1] && this.Map.hasLayer( this.wallMarker[1] ) ){
            this.Map.removeLayer( this.wallMarker[1] )
            this.wallMarker[1] = null
          }
          this.unSelectLayer()
        }
        resolve('resolved')
      })
    }
    // 
    clickOnFenceMode(e, drag){
      e    = e || undefined;
      drag = drag || undefined;
      // Si Drag du marker
      if( drag != undefined ){
        e.latlng = drag.target._latlng
      }
      var locdata = {};
      locdata.gps_latlng = e.latlng.lat +', '+ e.latlng.lng;
       var distPre   = 0;
       var distPost  = 1000000;
       var calcPoint = { lat:e.latlng.lat, lng:e.latlng.lng };

       // Recherche du point le plus proche d'une ligne
      for( const splId in this.wallSimpleList ){
        // Si le premier existe déjà on saute les autres layerState
        if( this.wallMarker[0] && (this.wallSimpleList[splId].sar_id !== this.wallMarker[0].sar_id) ){
          continue
        }
        distPre = turfDistance([e.latlng.lat, e.latlng.lng], this.wallSimpleList[splId].latlng)
        if( distPre < distPost ){
          calcPoint.lat    = this.wallSimpleList[splId].latlng[0]
          calcPoint.lng    = this.wallSimpleList[splId].latlng[1]
          calcPoint.sar_id = this.wallSimpleList[splId].sar_id
          calcPoint.layer  = this.wallSimpleList[splId].layer
          distPost         = distPre //layerMatch    = 'vSlayer';
        }
      }
      /**********Traitement du deuxième point */

      var self = this
      let wall = ( !this.wallMarker[0] ) ? 0 : 1
      if( !this.wallMarker[1] && !drag ){
        this.wallMarker[wall] = this.buildMarker({ iconSize: [25,39], iconAnchor: [-4,42], draggable: true, type: 'flagicon-flag_icon_green', latLng: [calcPoint.lat, calcPoint.lng] });
        this.wallMarker[wall].sar_id = calcPoint.sar_id
        this.wallMarker[wall].layerSource = calcPoint.layer
        this.wallMarker[wall].on('dragend', (e) => {
          self.clickOnFenceMode(e.target, e);
        })
        this.Map.addLayer(this.wallMarker[wall])
        if(wall == 1){ this.wallMarker[0].dragging.disable() }
      } else {
        wall = ( !this.wallMarker[1] ) ? 0 : 1
        let newLatLng = new L.LatLng(calcPoint.lat, calcPoint.lng);
        this.wallMarker[wall].setLatLng(newLatLng); 
      }
      if( wall == 1 ){
        let mileage = turfDistance(
          [this.wallMarker[0]._latlng.lat, this.wallMarker[0]._latlng.lng], 
          [this.wallMarker[1]._latlng.lat, this.wallMarker[1]._latlng.lng], 
        )
        mileage = Math.round( mileage * 1000 )
        this.wallMarker[1].bindPopup("<b>Longueur :</b><br>" + mileage + " mètres").openPopup();
        // selection du layer
        this.wallMarker[wall].layerSource.feature.properties.sar_selected = true
        self.selectedSar.push( this.wallMarker[wall].layerSource.feature.properties.sar_id )
        this.wallMarker[wall].layerSource.setStyle( { color: '#E65100', fillColor : '#FFD600', opacity:1, fillOpacity:1 } )
      }
      //this.addLocMarker({ lat: calcPoint.lat, lng: calcPoint.lng });
    }
    /* ******************************************************************************************
                Helpers
    ******************************************************************************************* */
    getwallMarker(){
      this.wallMarker[2] = turfDistance(
        [this.wallMarker[0]._latlng.lat, this.wallMarker[0]._latlng.lng], 
        [this.wallMarker[1]._latlng.lat, this.wallMarker[1]._latlng.lng], 
      )
      this.wallMarker[2] = Math.round( this.wallMarker[2] * 1000 )
      return this.wallMarker
    }
    // Get area of layer
    //getAreaOfLayerGroup( layer, id ){
    //  return new Promise( (resolve) => {
    //    let list = []
    //    this[layer][id].eachLayer( (layer) => {
    //      let obj = {
    //        sar_id: layer?.feature?.properties?.sar_id,
    //        sar_area_auto: 0
    //      }
    //      list.push( obj )
    //    })
    //    resolve(list)
    //  })
    //}
    // Activer le mode alert
    activeAlert(mode) {
      mode = mode || false
      this.modeAlert = mode;
    }

    // Ajout d'alerte existante
    addAlert(alertObj) {
      var marker = this.buildMarker( { draggable: false, type: 'gen', latLng: [alertObj.eve_lat, alertObj.eve_lng], color: alertObj.eve_color } );
      if( alertObj.eve_typ_id == 'MEV04' ){
        marker = this.buildMarker({
          draggable: false, type: 'gen', latLng: [alertObj.eve_lat, alertObj.eve_lng], color: '#F44336', text: "" + alertObj.eve_id
        })
      }
      marker._eve_id = alertObj.eve_id
      //touchstart touchend,touchmove
      var mapTapHoldTimeout
      marker.on('mousedown touchstart', () => {
        if( !marker.options.draggable ){
          mapTapHoldTimeout = setTimeout( () => {
            marker.options.draggable = true
            marker.dragging.enable()
            marker.setIcon( this.buildIcon( { draggable: false, type: 'gen', latLng: [alertObj.eve_lat, alertObj.eve_lng], color: 'blue' } ) )
          }, 500)
        }
      })
      marker.on('dragend', (e) => {
        bus.$emit('event:updateloc', { eve_id: marker._eve_id, eve_lat: e.target._latlng.lat, eve_lng: e.target._latlng.lng })
        marker.options.draggable = false
        marker.dragging.disable()
        if( alertObj.eve_typ_id == 'MEV04' ){
          marker.setIcon( this.buildIcon( { draggable: false, type: 'gen', latLng: [alertObj.eve_lat, alertObj.eve_lng], color: '#F44336', text: "" + alertObj.eve_id } ) )
        } else {
          marker.setIcon( this.buildIcon( { draggable: false, type: 'gen', latLng: [alertObj.eve_lat, alertObj.eve_lng], color: alertObj.eve_color } ) )
        }
        
      })
      //marker.options.draggable = true
      marker.on('mouseout touchend', () => { if ( mapTapHoldTimeout ) { clearTimeout(mapTapHoldTimeout ) } })
      marker.on({ 
        click: (e) => {
          if( !marker.options.draggable ){
            bus.$emit('event:open', { 
              eve_id: alertObj.eve_id, 
              eve_table_id: alertObj.eve_table_id, eve_xxx_id: alertObj.eve_xxx_id,
              eve_lat: e.latlng.lat, eve_lng: e.latlng.lng
            })
          }
        }
      });
      this.layerAlert.addLayer( marker );
    }

    removeAllAlert() {
      this.layerAlert.eachLayer(layer => {
        this.layerAlert.removeLayer(layer)
      })
    }

    // Ajouter un marker Alerte
    addAlertMarker(active) {
      var self = this;
      if (active) {
        this.Map.on('click', function (e) {
          self.addLocMarker(e.latlng);
        });
      } else {
        this.Map.off('click');
        if (this.markerDrag !== undefined && this.markerDrag !== null) {
          this.Map.removeLayer(this.markerDrag);
          this.markerDrag = null;
        }
      }
    }

    // Supprimer les layers de areaList
    removeAllLayer(){
      return new Promise( (resolve) => {
        for(const key in this.areaList){
          this.areaList[key].eachLayer( (layer) => {
            if( this.Map.hasLayer( layer ) ){
              this.Map.removeLayer(layer)
            }
          })
        }
        this.workLines.eachLayer( (layer) => {
          if( this.Map.hasLayer( layer ) ){
            this.Map.removeLayer(layer)
          }
        })
        this.areaList = []

        resolve('resolved')
      })
    }
    // Supprimer les layers de areaList
    removeAllSubAreaLayer(){
      return new Promise( (resolve) => {
        for( const key in this.areaList ){
          this.Map.removeLayer(this.areaList[key])
        }
        resolve('resolved')
      })
    }

    // Supprimer les layers de areaList
    removeLinearTasks(){
      return new Promise( (resolve) => {
        this.workLines.eachLayer( (layer) => {
          this.Map.removeLayer(layer)
        })
      resolve('resolved')
      })
    }

    udpateLayerStyle(items){
      items.forEach(element => {
        if( this.disctricsById['area_' + element] ){
          this.disctricsById['area_' + element].setStyle( { weight: 3, fillOpacity: 0.2, fillColor: "#607D8B", opacity: 1, color: "red" } )
        }
      });
    }

    locateMe(){
        var self = this;
        this.Map.locate({setView: false, watch: true, enableHighAccuracy: true}).on( 'locationfound', function(e){
          if( parseInt(e.accuracy) > 500 ){
            self.focusOnUser( { latlng: { lat: 48.818854, lng: 2.319438 } }, 15 );
          } else {
            self.focusOnUser(e);
          }
        });
      }
      setLayer(value) {
        switch(value) {
          case 'base':
            return this._setBaseLayer();
          case 'notile':
            return this._setNoTileLayer();
          default:
            return this._setBaseLayer();
        }
      }

      // Ajouter une zone (cercle)
    addCircleZone(pos){
      if( pos.lat == 0 || pos.lng == 0 || pos.lat == undefined ){
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if( this.circleMarker == null || this.circleMarker == undefined ){
        this.circleMarker = L.circle([pos[1], pos[0]], {
            color: '#2196F3',
            fillColor: '#2196F3',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.Map);
      } else {
        this.circleMarker.setLatLng( [pos[1], pos[0]] );
      }
      setTimeout( () => {
        this.Map.removeLayer(this.circleMarker)
      }, 1500);
    }

    panTo(pos, zoom){
      zoom = zoom || 19;
      if( pos.lat !== undefined ){
        pos[1] = pos.lat;
        pos[0] = pos.lng;
      }
      this.Map.setView( [pos[1], pos[0]], zoom, {
        pan: { animate: true, duration: 1.50 },
        zoom: { animate: true }
      });
    }

    // Ajouter un marqeur de géolocalisation
    addLocMarker(pos) {
      if (pos.lat == 0 || pos.lng == 0) {
        pos.lat = this.defautLatLng[0];
        pos.lng = this.defautLatLng[1];
      }
      if ((this.markerDrag == null || this.markerDrag == undefined) ) {
        this.markerDrag = this.buildMarker({ draggable: true, type: 'orange-warning', latLng: [pos.lat, pos.lng] });
        //this.markerDrag.addTo(this.Map);
        this.layerAlert.addLayer(this.markerDrag);
        var self = this;
        // Open alert on click
        if (self.modeAlert) {
          this.markerDrag.on({
            click: function (e) {
              bus.$emit('event:open', { eve_id: 0, eve_lat: e.latlng.lat, eve_lng: e.latlng.lng })
            }
          });
        }
      } else {
        this.markerDrag.setLatLng(new this.leaflet.LatLng(pos.lat, pos.lng));
      }

    }

    buildMarker(obj) {
      let title     = obj.title || '';
      let popup     = obj.popup || '';
      let draggable = obj.draggable || false
      let opacity   = obj.opacity || 0
      let marker    = L.marker(obj.latLng, { //this.leaflet.marker(obj.latLng, {
        title: title,
        icon: this.buildIcon(obj),
        draggable: draggable
      });
      marker.on('dragend', () => {
        var position = marker.getLatLng();
        this.markerLoc = [ position.lat, position.lng ];
      });
      if( popup.length > 0 ){
        marker.bindPopup(popup);
      }
      if(opacity){
        marker.setOpacity(opacity)
      }

      return marker;
    }

    buildIcon(obj) {
      let type = obj.type;
      let html = '<div class="marker-svg marker-blue"><center></center></div>';
      switch(type) {
        case 'truck':
          html = this.svgIcon['truck'];
          break;
        case 'gen':
          if( obj.color ){
            html = '<div><svg style="fill:' + obj.color + ';" xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          } else {
            html = '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="1239.2 -915.6 332.5 593">';  
          }
          html += '<path opacity=".2" d="M1544.6-459c-46.1-15.4-305.3-36.5-305.3 13.4v3.8c0 48 163.2 119.1 163.2 119.1 184.3-99.8 193.9-119 142.1-136.3z"/>';
          html += '<path d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path fill="#000" opacity=".3" d="M1398.6-339.9s-159.4-272.7-159.4-458.9 251.6-107.5 297.6-49.9c50 63.3 40.4 140.1-138.2 508.8z" />';
          html += '<path d="M1398.6-339.9s-159.4-265-159.4-447.4 234.3-105.6 276.5-49.9c48.1 61.4 55.7 97.9-117.1 497.3z" />';
          if( obj.text && obj.text.length ){
            html += '<ellipse style="fill:#ffffff;stroke:#33756e;stroke-width:0.68801081;stroke-miterlimit:10" ry="98.495834" rx="151.41518" id="circle10" cy="-759.33984" cx="1392.7476" stroke-miterlimit="10" />';
            html += '<text transform="scale(0.89469964,1.1176935)" id="text824" y="-637.34656" x="1560.7899"';
            html += ' style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:36.66627502px;line-height:1.25;font-family:sans-serif;-inkscape-font-specification:\'sans-serif Bold\';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.3749851"';
            html += ' xml:space="preserve"><tspan';
            html += ' style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:117.33207703px;font-family:sans-serif;-inkscape-font-specification:\'sans-serif Bold\';text-align:center;text-anchor:middle;stroke-width:1.3749851"';
            html += ' y="-637.34656" x="1560.7899" id="tspan822">' + obj.text + '</tspan></text>';
          } else {
            html += '<circle fill="#FFF" stroke="#33756E" stroke-width=".4" stroke-miterlimit="10" cx="1392.9" cy="-731.7" r="71" /></svg>';
          }

          if( obj.marker ){
            html += '<img src="./' + obj.marker + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;">';
          }
          html += '<div>';
          break;
        default:
          var p = type.split("-");
          if( p[1] !== undefined ){
            html= '<div class="marker-svg marker-' + p[0] + '"><img src="./' + p[1] + '.svg" style="width: 25px; margin: 0 auto; position:absolute; top:4px; right:6px;"></div>';
          } else {
            html = this.svgIcon['blue'];
          }
      }
      let iconSize    = obj.iconSize || [34, 56]
      let iconAnchor  = obj.iconAnchor || [17, 55]
      let popupAnchor = obj.popupAnchor || [0, -56]
      return this.leaflet.divIcon({ 
        html: html, 
        iconSize   : iconSize,
        iconAnchor : iconAnchor,
        popupAnchor: popupAnchor,
        className: 'marker-transp'
      });
    }

    _setBaseLayer() {
      return L.tileLayer('https://{s}.tiles.technosig.fr/' + 'rj3ry0gjlkxx7feia0mx43ut' + '/wmts/osmtiles/webmercator/{z}/{x}/{y}.png', { //this.layerUrl
        attribution: 'TECHNOSIG',
        maxNativeZoom: 18, maxZoom : 24,
        id: 'osm'
      });
    }
    _setNoTileLayer() {
      return L.tileLayer('', { //this.layerUrl
        attribution: 'TECHNOSIG - Offline',
        id: 'osm cache'
      });
    }
    setTileLayer( link ){
      this.Map.removeLayer(this.baseLayerMap);
      this.baseLayerMap = L.tileLayer(link, {
        attribution: 'TECHNOSIG',
        id: 'osm',
        maxZoom : 28, minZoom: -20, maxNativeZoom: 18
      });
      this.Map.addLayer(this.baseLayerMap);
    }

    displayGps(data){
      var tmp = { p: {}, d: {} }
      data.positions = data.positions || []
      data.devices   = data.devices  || []
      data.positions.forEach((p) => { tmp.p['p_' + p.id] = p; });
      data.devices.forEach((d) => { tmp.d['d_' + d.id] = d; });

      Object.keys(tmp.d).map(function(prop) {
          return tmp.d[prop];
      }).filter((d) => {
          return (typeof tmp.p['p_' + d.positionId] !== 'undefined');
      }).map((d) => {
          var p = tmp.p['p_' + d.positionId];
          var angle = p.course + 90;
          if (angle > 360) {
              angle -= 360;
          }
          var gpsDate    = new Date(p.deviceTime);
          return {
              name: d.attributes.name || d.name,
              gps:  d.attributes.name || d.name,
              imei: d.uniqueId,
              gps_imei: d.uniqueId,
              type:  d.attributes.office || '-',
              description: d.attributes.registration,
              pos: new L.LatLng(p.latitude, p.longitude),
              lat: p.latitude,
              lng: p.longitude,
              angle: angle,
              date: gpsDate.toLocaleDateString("fr-FR"), //p.deviceTime,
              time: ('0' + gpsDate.getHours() ).slice(-2) + ':' + ('0' + gpsDate.getMinutes()).slice(-2),
              speed: p.speed,
              usr_lastname : d.attributes.pilote || d.name,
              usr_firstname: '',
              usr_mobile   : d.attributes.phone || ''
          };
      }).forEach( (gpsobj) => {
          this.refreshMapUser(gpsobj);
      });
    }

    refreshMapUser(recep) {
      var now        = new Date();
      var jour       = ('0' + now.getDate()).slice(-2);
      var heure      = ('0' + now.getHours()  ).slice(-2);
      //var minute     = ('0' + now.getMinutes()).slice(-2);
      var opacityIco = 1;
      var tabDate
      if( recep.time == undefined ){ recep.time = '00:00'; }
      var tabTime = recep.time.split(":");
      var difTime = parseInt(heure) - parseInt(tabTime[0]);
      if( difTime > 1 ){ opacityIco = 0.5; }
      if( difTime > 3 ){ opacityIco = 0.3; }
      if( recep.date !== undefined && recep.date !== null ){
        tabDate = recep.date.split("/");
        if( tabDate[0] !== jour ){
          opacityIco = 0.1;
        }
        
      } else {
        recep.date  = '00/00/0000';
        tabDate = recep.date.split("/");
        opacityIco = 0.1;
      }
      //$.each( CoreSys.gpsPos, function( key, val ) { CoreSys.voc.map.removeLayer( CoreSys.voc.gpsPos[key] ); });
      if( recep.name == undefined ){ recep.name = recep.gps }
        let popf  = '';
        popf += '<span id="mpuser">'+ recep.name +'</span><br />';
        popf += '<span>Date : '+ recep.date +'</span><br />';
        popf += '<span>Heure: '+ recep.time +'</span><br />';
        var speed     = recep.speed;
        popf += '<span>Vitesse: '+ speed +'</span><br />';
        popf += '<span>Fonction: '+ recep.type +'</span><br />';
        popf += '<i class="fa fa-user"></i> : ' + recep.usr_lastname + ' ' + recep.usr_firstname + '<br />';
        popf += '<i class="fa fa-phone"></i> : <a href="tel:' + recep.usr_mobile + '"> ' + recep.usr_mobile + '</a>';
      var i = 0
      this.truckGps.eachLayer( (marker) => {
        if( recep.gps_imei == marker._imei ){
          var newLatLng = new L.LatLng(recep.lat, recep.lng);
          marker._popup._content = popf;
          marker.setLatLng(newLatLng);
          i++;
        }
      });
      if( i == 0 && recep.lat != undefined && recep.lng != undefined ){
        let marker = this.buildMarker({ 
          type: 'truck', latLng: [recep.lat, recep.lng], opacity:opacityIco, popup: popf
        });
        marker._imei = recep.gps_imei;
        this.truckGps.addLayer(marker)
      }
    }

    displayWeekTask(state){
      if(state){
        for(const areId in this.areaList){
          this.areaList[areId].eachLayer( (layer) => {
            if ( layer.feature?.properties?.tsk_week != 0 ) {
              //var center = { lat:layer.getBounds()._northEast.lat, lng:layer.getBounds()._southWest.lng };
              var center = layer.getBounds().getCenter();
              var wpoly = this.mapLineNumber(layer.feature.properties.tsk_week, center);
              var polNum = L.polyline(wpoly, {color: 'black', weight:1, noClip:true});
              this.layerWeek.addLayer(polNum);
            }
          })
        }
      } else {
        this.layerWeek.eachLayer(layer => {
          this.layerWeek.removeLayer(layer)
        })
      }
    }

    mapLineNumber = function(num, point) {
      var zoom   = this.Map.getZoom();
      var latAdd = 0.0002275; //0.00026; // lat *0.875
      var lngAdd = 0.00035; //0.0004; // lng
      if( zoom >= 16 ){
        latAdd = latAdd / 2;
        lngAdd = lngAdd / 2;
      }
      var pNum   = [];
      var poly   = [];
      var digits = num.toString(10).split("").map(function(t){return parseInt(t)});
      //var digits = num.toString(10).split("").map(Number);
      var numMat = function(point, numCode){
        var line = [];
        var ret  = [];
        line[0]  = [ [point.lat, point.lng], [point.lat, point.lng + lngAdd] ];
        line[1]  = [ [point.lat, point.lng + lngAdd], [point.lat - latAdd, point.lng + lngAdd] ];
        line[2]  = [ [point.lat - latAdd, point.lng + lngAdd], [point.lat - latAdd * 2, point.lng + lngAdd] ];
        line[3]  = [ [point.lat - latAdd * 2, point.lng + lngAdd], [point.lat - latAdd * 2, point.lng] ];
        line[4]  = [ [point.lat - latAdd * 2, point.lng], [point.lat - latAdd, point.lng] ];
        line[5]  = [ [point.lat - latAdd, point.lng], [point.lat, point.lng] ];
        line[6]  = [ [point.lat - latAdd, point.lng], [point.lat - latAdd, point.lng + lngAdd] ];
        numCode.forEach(function(code){
          ret.push( line[code] );
        });
        return ret;
      }
      pNum[0] = [0,1,2,3,4,5];
      pNum[1] = [1,2];
      pNum[2] = [0,1,6,4,3];
      pNum[3] = [0,1,6,2,3];
      pNum[4] = [5,6,1,2];
      pNum[5] = [0,5,6,2,3];
      pNum[6] = [0,5,6,2,3,4];
      pNum[7] = [0,1,2];
      pNum[8] = [0,1,2,3,4,5,6];
      pNum[9] = [0,1,6,5,2,3];
      digits.forEach(function(numInt){
        poly.push( numMat( point, pNum[numInt] ) );
        point.lng = point.lng + lngAdd * 1.7;
      });
      //var test = L.polyline(poly, {color: 'red', weight:1, noClip:true}).addTo(CoreSys.voc.map);
      return poly;
    }
}

export default LfMap;